<template>
	<div class="zui-flex-column" >
		<div style="width: 100%">
			<hangar :isPhone="isPhone" :title="$t('comparison.title')" />
		</div>
		<div id="back"  v-show="isPhone" @click="back" style="z-index: 999; position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;">
		</div>
		<div class="zui-bg-white box">
			<div class="tableWraper" >
				<el-table class="tableBox" :border="true" :show-header="false" :highlight-current-row="true" size="mini" :data="tableData">
					<el-table-column fixed prop="title" :width="tabTitleWidth" ></el-table-column>
					<el-table-column label="value1" v-for="(item) in ids.length" :prop="'value'+item" :key="item" width="300"></el-table-column>
				</el-table>
			</div>
		</div>
	</div>
	
</template>
<script>
	import hangar from "../components/hangarCom";
	// import {getAirInfo} from '../api/api';
	import {
		mapState
	} from "vuex";
	var field = [
		'title',
		'engineModel',
		'apuName',
		'distance',
		'cruiseHeight',
		'cruiseSpeed',
		'seatNum',
		'weight',
		'planeHeight',
		'planeLength',
		'planeLength',
		'height',
	]
	export default {
		components: {
			hangar,
		},
		data() {
			return {
				tabTitleWidth:200,
				ids: [],
				tableData: [
					{
						title:'机型'
					},
					{
						title:'发动机'
					},
					{
						title:'APU'
					},
					{
						title:'最大航程'
					},
					{
						title:'巡航高度'
					},
					{
						title:'最大速度'
					},
					{
						title:'座位数'
					},
					{
						title:'最大起飞重量'
					},
					{
						title:'客舱高度'
					},
					{
						title:'客舱长度'
					},
					{
						title:'翼展'
					},
					{
						title:'飞机高度'
					},
				],
				index:0
			}
		},
		watch:{
			language(){
				//设置英文
				this.setEn()
			}
		},
		computed: {
			...mapState(["language"]),
		},
		methods:{
			setEn(){
				var tableData=this.tableData
					
				for(let i in tableData){
					i
				this.index+=1
				let index=this.index
				tableData[i].title=this.$t('comparison.mesg'+index)
				}
				this.index=0
			},
		},
		created: function() {
			//页面跳转时设置英文
			this.setEn()
if(/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)){
				this.isPhone=true
				this.tabTitleWidth=100
			}else{
				this.isPhone=false
				this.tabTitleWidth=200
			}
			
			let params = this.$route.params;
			// console.log(params);
			if (params.ids) {
				this.ids = JSON.parse(params.ids)
				// console.log(this.ids);
				this.$zui.arrayEach(field,(value,key)=>{
					this.$zui.arrayEach(this.ids,(item,index)=>{
						this.$zui.each(item,(k,v)=>{
							if(k == value){
								// console.log(k,value,v,index);
								this.$zui.assign(this.tableData[key],'value'+(index+1),v)
							}
						})
					})
				})
				// console.log(this.tableData);
			}else{
				this.$router.push({ name: 'Hangar'});
			}
			
		},
	};
</script>
<style lang="scss" scoped>
	.box {
		display: flex;
		justify-content: center;
		padding: 25px 0;
		.tableWraper {
			width: 80%;
		}
	}
	@media screen and (max-width: 768px) {
		.box{
			.tableWraper {
				width: 96%;
			}
		}
	}
</style>
